import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  Phone,
  VideoAndText,
  BannerSmall,
  CallUs,
  BannerLinks,
  ProjectPagination
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'
import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.rauchmehl.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.klepschgroup.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.tq.jpg'

const RauchMehl = () => {
  const links = generateBreadcrumbsLinks('/projekte/rauchmehl', 'Rauchmehl')
  return (
    <Layout margin={false} color="black">
    <Helmet
      title="Rauch Mehl - vom Korn zum naturreinen Mehl"
      meta={[
        {
          name: 'description',
          content:
            'Die neue Website von Rauch Mehl besticht durch eine beeindruckende Produktwelt und Rezepte. Als Traditionsunternehmen dürfen natürlich auch Infos über die Geschichte und Entstehung nicht fehlen. Umfangreiche Informationen zu den Produkten und deren Verwendung runden das Angebot ab.'
        },
        {
          name: 'keywords',
          content:
            'Rauchmehl holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Rauchmühle, Rauch Mehl, Mehl, Rezept, Rezepte'
        },
        {
          property: 'og:description',
          content:
            'Die neue Website von Rauch Mehl besticht durch eine beeindruckende Produktwelt und Rezepte. Als Traditionsunternehmen dürfen natürlich auch Infos über die Geschichte und Entstehung nicht fehlen. Umfangreiche Informationen zu den Produkten und deren Verwendung runden das Angebot ab.'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/header.rauchmehl-1ba66ccde6dc0d5d28f2edeb7b44a307.jpg'
        }
      ]}
    />
      <BannerSmall image={BannerImage} title={
        <span>Die <nobr>Rauchmühle</nobr><br/>im neuen Gewand</span>
        }
      />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Vom Korn zum <br />natur&#173;reinen Mehl
          </div>
        }
        text="Die neue Website von Rauch Mehl besticht durch eine beeindruckende Produktwelt und Rezepte. Als Traditionsunternehmen dürfen natürlich auch Infos über die Geschichte und Entstehung nicht fehlen. Umfangreiche Informationen zu den Produkten und deren Verwendung runden das Angebot ab."
        buttons={[
          {link: 'https://www.rauchmehl.at', text: 'Zur Rauchmehl Website'},
        ]}
      />
      <Phone
        title="Ein Bilderbuch Wordpress Projekt"
        description="Die neue Rauchmehl Website verfügt über einen Produktkatalog, Rezepte-Kollektion mit Filtermöglichkeit im Frontend und ein rundum schönes Design mit einladenden Bildern. Für eine der Zeit entsprechenden Usability navigiert man die Website mobil über eine aufklappbare Burgernavigation."
        mainDescription="Responsives Design, Filter, Burgernavigation"
        link="https://www.rauchmehl.at/"
        bgColor="#FFFFFF"
      />
      <CallUs contacts={contacts} title="Gerne für Sie da" />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
      imageLeft={image4}
      leftLink="/projekte/klepsch-group"
      titleModsLeft={['white']}
      titleLeft="Relaunch Klepsch-Gruppe"
      imageRight={image5}
      rightLink="/projekte/relaunch-tq-group"
      titleModsRight={['white']}
      titleRight="Relaunch TQ-Group"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default RauchMehl
